import Image from "next/image";
import Link from "next/link";
import { useContext } from "react";
import { useStateMachine } from "little-state-machine";
import { FaSignOutAlt } from "react-icons/fa";

import updateActionUserData from "../../actions/actions";

import { AuthContext } from "../../contexts/AuthContext";

export default function Header({
  showHeader,
  title,
  showMinhasInscricoes = true,
}) {
  const { signOut, userLogged } = useContext(AuthContext);

  if (showHeader == "false") {
    return <></>;
  }

  const { actions, state } = useStateMachine({ updateActionUserData });

  return (
    <>
      <div className="w-full header-fadisma shadow px-2 m-0 bg-white">
        <div className="container mx-auto">
          <div
            className={
              userLogged
                ? "w-full flex flex-col lg:flex-row items-center justify-between"
                : "w-full flex flex-col lg:flex-row items-center justify-center"
            }
          >
            <a href="/">
              <Image
                src="/images/fadisma.svg"
                alt="Fadisma"
                width="150"
                height="61"
              />
            </a>

            <div className="flex items-center mb-2">
              {/* <div className="link-info flex flex-col justify-center items-center lg:items-end">
                <span className="font-medium text-primaria">
                  Curso de como vender curso
                </span>
                <p className="text-sm">Data: 05/01/2022</p>
              </div> */}

              {userLogged && (
                <>
                  <div className="lg:sep-vertical"></div>
                  <div className="link-info flex flex-col justify-center items-end py-2 px-3 pr-0 rounded  text-white">
                    <span className="font-medium flex items-center text-sm text-secundaria">
                      {/* <FaUser className="mr-2 mb-1" size="13px" />{" "} */}
                      {state.userData.nome}
                    </span>
                  </div>
                  <div className="sep-vertical"></div>
                  <div
                    className="text-sm cursor-pointer flex items-center"
                    onClick={signOut}
                  >
                    <FaSignOutAlt className="mr-1" /> Sair
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="title-header mb-3 bg-primaria w-full shadow">
        <div className="title container mx-auto p-3 lg:py-3 lg:px-8 ">
          {showMinhasInscricoes && (
            <>
              <div className="w-full max-w-7xl flex flex-col lg:flex-row justify-between items-center mb-2 lg:mb-0">
                <h1
                  className={
                    !userLogged
                      ? "text-lg lg:text-2xl font-extrabold uppercase text-white text-center w-auto float-left mx-auto"
                      : "text-lg lg:text-2xl font-extrabold uppercase text-white text-center w-auto float-left"
                  }
                >
                  {title}
                </h1>

                {userLogged && (
                  <Link href="/minhas-inscricoes/">
                    <button
                      type="submit"
                      className="ipt-btn btn-icon btn-primary-action mb-0 hover:bg-secundaria hover:border-secundaria bg-verde btn-minhas mt-2 lg:mt-0"
                    >
                      Minhas Inscrições
                    </button>
                  </Link>
                )}
              </div>
            </>
          )}

          {!showMinhasInscricoes && (
            <>
              <div className="w-full max-w-7xl flex flex-row justify-center items-center">
                <h1 className="text-xl lg:text-2xl font-extrabold uppercase text-white text-center w-auto">
                  {title}
                </h1>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
