import Head from "next/head";

import { useState, useEffect, useContext } from "react";

import Header from "@/components/ui/Header";
import Footer from "@/components/ui/Footer";
import Login from "@/components/login/login.js";
/* import OctadeskScript from "@/components/scripts/octadesk-script.js";
 */
import { useRouter } from "next/router";

import { AuthContext } from "../contexts/AuthContext";

export default function LoginPage() {
  const { userLogged } = useContext(AuthContext);

  const pageTitle = "Inscrições FADISMA";

  return (
    <div className="app-wrapper bg-login h-full">
      <Head>
        <title>{pageTitle}</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <Header title={pageTitle} showMinhasInscricoes={false} />

      <main className="main">
        <Login />
        {/* <img src="https://mkt.fadisma.com.br/mtracking.gif?page_url=teste_adblock" width="1" height="1"  /> */}
      </main>

      <Footer isAbsolute={false} />

      {/* {!userLogged && <OctadeskScript />} */}
    </div>
  );
}
