import { useState, useContext, useEffect } from "react";
import { useRouter } from "next/router";

import { FaLock, FaArrowRight, FaEye, FaEyeSlash } from "react-icons/fa";

import { FiLogIn } from "react-icons/fi";

import updateActionUserData from "../../actions/actions";
import InputUsuarioLogin from "@/components/inputs/input-usuario";
import { useStateMachine, createStore } from "little-state-machine";
import { useForm, Controller } from "react-hook-form";
import { autenticaUsuario } from "../../api/functions";
import { handleForgot } from "@/components/ui/modal-functions";
import { AuthContext } from "../../contexts/AuthContext";
import Loader from "@/components/ui/Loader";
import Cookie from "js-cookie";

export default function LoginForm() {
  const [passwordType, setPasswordType] = useState("password");
  const [foreign, setForeign] = useState(false);
  const [loading, setLoading] = useState(false);
  /*   const [stopLoadingToken, setStopLoadingToken] = useState(
    Cookie.get("fdStopLoading")
  ); */

  const { signIn, endloading } = useContext(AuthContext);

  const router = useRouter();

  function togglePassword() {
    passwordType == "password"
      ? setPasswordType("text")
      : setPasswordType("password");
  }

  const { actions, state } = useStateMachine({ updateActionUserData });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: state.userData,
  });
  async function onSubmit(data) {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
    const resLogin = await signIn(data);
  }
  // console.log(errors);

  function toggleForeign() {
    if (foreign) {
      setForeign(false);
    } else {
      setForeign(true);
    }
  }

  return (
    <>
      {loading && (
        <Loader loading={loading} msg="Aguarde enquanto validamos seus dados" />
      )}
      <h2 className="text-xl font-medium text-primaria mb-2 text-center">
        Já possui usuário?
      </h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col mb-3">
          <InputUsuarioLogin
            foreign={foreign}
            toggleForeign={toggleForeign}
            register={register}
            errors={errors}
            Controller={Controller}
            control={control}
          />
        </div>
        <div className="flex flex-col mb-6">
          <label className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600">
            Senha:
          </label>
          <div className="relative">
            <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
              <span>
                <FaLock size="18px" />
              </span>
            </div>

            <input
              id="password"
              type={passwordType}
              className="ipt-text pl-10 pr-4 py-4 mb-0 rounded-lg w-full focus:outline-none focus:border-secundaria"
              placeholder="Senha"
              {...register("senha", {
                required: {
                  value: true,
                  message: "Este campo é obrigatório",
                },
              })}
            />

            <div
              className="inline-flex items-center justify-center absolute right-2 top-0 h-full w-10 text-gray-400"
              onClick={() => {
                togglePassword();
              }}
            >
              <span>
                {passwordType == "password" ? (
                  <FaEye size="18px" />
                ) : (
                  <FaEyeSlash size="18px" />
                )}
              </span>
            </div>
          </div>
          {errors.senha && (
            <span className="text-xs text-red-600 mt-1 animate-fadeIn">
              {errors.senha?.message}
            </span>
          )}
          <span className="text-xs text-gray-500 mt-2 text-sm mb-1 px-2">
            No 1º acesso, usar sua data de nascimento como senha inserindo
            apenas números (ddmmaaaa)
          </span>
        </div>

        <div className="flex items-center mb-3 -mt-4 px-2">
          <div className="flex flex-row items-center justify-end ml-auto">
            <div
              className="block text-xs sm:text-sm text-secundaria hover:text-primaria leading-10 cursor-pointer"
              onClick={handleForgot}
            >
              Já sou cadastrado?
            </div>
            <div className="text-xxs mx-1 text-primaria">/</div>
            <a
              href="https://fadismaid.fadisma.com.br/recovery/"
              target="_blank"
              title="Recuperar senha"
            >
              <div className="block text-xs sm:text-sm text-secundaria hover:text-primaria leading-10 cursor-pointer">
                Recuperar senha
              </div>
            </a>
          </div>
        </div>

        <div className="flex w-full">
          <button
            type="submit"
            className="flex items-center justify-center focus:outline-none text-white text-xs sm:text-base rounded py-3 w-full ipt-btn"
          >
            <span>
              <FiLogIn className="mr-2" />
            </span>
            <span className="uppercase text-base">Entrar</span>
          </button>
        </div>
      </form>
    </>
  );
}
