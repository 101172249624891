import Link from "next/link";

import { FaUserPlus } from "react-icons/fa";

export default function FirstLoginForm() {
  return (
    <>
      <h2 className="text-xl font-medium text-primaria mb-2 mt-3 text-center">
        Primeira vez por aqui? <br />
      </h2>

      <div className="flex justify-center items-center mt-2 w-full">
        <Link href="/cadastro">
          <button
            type="submit"
            className="flex items-center justify-center focus:outline-none text-white text-sm sm:text-base rounded py-3 w-full ipt-btn btn-green"
          >
            <span>
              <FaUserPlus className="mr-2" />
            </span>
            <span className="uppercase text-base tracking-tight">
              Quero iniciar minha inscrição
            </span>
          </button>
        </Link>
      </div>
    </>
  );
}
