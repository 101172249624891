import { FaIdCard, FaEnvelope } from "react-icons/fa";
import Swal from "sweetalert2";

export default function InputUsuarioLogin({
  foreign,
  toggleForeign,
  register,
  errors,
  Controller,
  control,
}) {
  function CPFMask(v) {
    v = v.replace(/\D/g, "");
    v = v.replace(/^(\d{3})(\d)/g, "$1.$2");
    v = v.replace(/^(\d{3})\.(\d{3})(\d)/, "$1.$2.$3");
    v = v.replace(/^(\d{3})\.(\d{3})\.(\d{3})(\d)/, "$1.$2.$3-$4");
    v = v.replace(/^(\d{3})\.(\d{3})\.(\d{3})\/(\d{2})(\d)/, "$1.$2.$3-$4");
    return v.substring(0, 14);
  }

  if (foreign) {
    return (
      <>
        <label className="mb-1 text-xs sm:text-base tracking-wide text-gray-600 flex justify-between label-form">
          Email:
          <div
            className="text-secundaria text-sm cursor-pointer"
            onClick={toggleForeign}
          >
            {!foreign && "Sou estrangeiro"}
            {foreign && "Sou brasileiro"}
          </div>
        </label>
        <div className="relative flex items-center">
          <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
            <FaEnvelope size="18px" />
          </div>

          <input
            type="email"
            className="ipt-text pl-10 pr-4 py-4 mb-0 rounded-lg w-full"
            placeholder="exemplo@gmail.com"
            {...register("email", {
              required: {
                value: true,
                message: "Este campo é obrigatório",
              },
              pattern: {
                value: /^\S+@\S+$/i,
                message: "Formato do email inválido",
              },
            })}
          />
        </div>
        {errors.email && (
          <span className="text-xs text-red-600 mt-1">
            {errors.email?.message}
          </span>
        )}
      </>
    );
  } else {
    return (
      <>
        <label className="mb-1 text-xs sm:text-base tracking-wide text-gray-600 flex justify-between label-form">
          CPF:
          <div
            className="text-secundaria text-sm cursor-pointer"
            onClick={toggleForeign}
          >
            {!foreign && "Sou estrangeiro"}
            {foreign && "Sou brasileiro"}
          </div>
        </label>
        <div className="relative flex items-center">
          <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
            <FaIdCard size="18px" />
          </div>

          <input
            id="cpf"
            type="text"
            name="cpf"
            onChange={(ev) => {
              test(ev);
            }}
            className="ipt-text pl-10 pr-4 py-4 mb-0 rounded-lg w-full focus:outline-none focus:border-secundaria"
            placeholder="000.000.000-00"
            {...register("cpf", {
              required: {
                value: true,
                message: "Este campo é obrigatório",
              },
              minLength: {
                value: 11,
                message: "Este campo deverá conter 11 dígitos",
              },
            })}
          />

          {/* <Controller
            render={({ field }) => (
              <input
                {...field}
                id="cpf"
                type="text"
                name="cpf"
                className="ipt-text pl-10 pr-4 py-4 mb-0 rounded-lg w-full focus:outline-none focus:border-secundaria"
                placeholder="000.000.000-00"
              />
            )}
            control={control}
            name="select"
            defaultValue={10}
          /> */}
        </div>
        {errors.cpf && (
          <span className="text-xs text-red-600 mt-1 animate-fadeIn">
            {errors.cpf?.message}
          </span>
        )}
      </>
    );
  }
}
