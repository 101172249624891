import { useState } from "react";
import { validaUsuario, getTermoAceite } from "api/functions";
import Swal from "sweetalert2";

export function handleForgot() {
  Swal.fire({
    showConfirmButton: true,
    showCancelButton: false,
    type: "info",
    confirmButtonText: "Lembrar meu usuário",
    closeOnConfirm: false,
    closeOnCancel: true,
    html:
      '<div class="confirma-assinatura">' +
      '<h2 class="text-2xl font-semibold"><FaCheck class="mr-1" />Lembrar Usuário</h2>' +
      '<p class="text-sm text-justify my-4">Preencha os seus dados abaixo para que possamos verificar a existência do seu usuário.</p>' +
      '<div class="flex flex-col justify-start items-start pb-6">' +
      '<label class="text-sm font-semibold mb-1">Nacionalidade:</label>' +
      '<select id="lbNacionalidade" class="ipt-text text-sm w-full border border-gray-400 shadow-sm rounded text-gray-700"/>' +
      '<option value="brasileira">Brasileira</option>' +
      '<option value="outra">Outra</option>' +
      "</select>" +
      '<label class="text-sm font-semibold mb-1 mt-2">CPF: </label>' +
      '<input id="lbCPF" type="text" name="cpf" class="ipt-text w-full focus:outline-none" placeholder="000.000.000-00" />' +
      '<label class="text-sm font-semibold mb-1 mt-2">Nome completo:</label>' +
      '<input id="lbNome" type="text" placeholder="Nome completo" value="" class="ipt-text text-sm focus:outline-none focus:border-transparent border border-gray-400 focus:ring-2 focus:ring-gray-300 w-full shadow-sm rounded text-gray-800"/>' +
      '<label class="text-sm font-semibold mb-1 mt-2">Data de nascimento</label>' +
      '<input id="lbData" type="date" placeholder="dd/mm/aaaaa" class="ipt-text text-sm w-full border border-gray-400 text-gray-700"/></div>' +
      "</div></div>",
    showLoaderOnConfirm: true,
    didRender: () => {
      // document
      //   .querySelector(".swal2-confirm")
      //   .setAttribute("disabled", "disabled");
    },
    preConfirm: () => {
      const userConsulta = {
        nacionalidade: document.getElementById("lbNacionalidade").value,
        cpf: document.getElementById("lbCPF").value,
        nome: document.getElementById("lbNome").value,
        dtnascimento: document.getElementById("lbData").value,
      };
      // enviaDadosConsultaUsuario(userConsulta);

      const resValida = validaUsuario(userConsulta.cpf);

      return resValida;
    },
    allowOutsideClick: () => !Swal.isLoading(),
  }).then((res) => {
    if (res.value && res.value.data.msg) {
      Swal.fire({
        title: res.value.data.msg,
      });
    } else if (res.isConfirmed && res.value && res.value.status == 200) {
      Swal.fire({
        title:
          "Usuário encontrado: <div class='user-found'>" +
          res.value.data.data[0].cpf +
          "</div>",
      });
    }
  });
}

export function handleAceiteLGPD() {
  Swal.fire({
    showConfirmButton: true,
    type: "info",
    confirmButtonText: "Dispensar",
    closeOnConfirm: false,
    html:
      '<div class="confirma-assinatura">' +
      '<h2 class="text-2xl font-semibold"><FaCheck class="mr-1" />Autorização para envio de dados pessoais</h2>' +
      '<p class="text-sm text-justify my-4">Ao selecionar a caixa abaixo você indica que está ciente e concorda com o tratamento de seus dados pessoais informados nesta plataforma (incluindo os dados complementares contidos nos documentos anexados) para fins de seleção e/ou ingresso em atividades educacionais, bem como, no que couber, com o disposto em nossa Política de Privacidade e/ou edital relacionado. O tratamento dos dados pessoais será realizado nos termos da Lei n. 13.709/18 (LGPD), respeitando-se as suas garantias e direitos previstos nesta lei. Para mais informações sobre o tratamento de dados pessoais realizado pela FADISMA, exercício de direitos e/ou outras demandas envolvendo seus dados pessoais você poderá acessar nossa Política de Privacidade, enviar um e-mail para o Encarregado (DPO) no endereço: dpo@fadisma.com.br, ou consultar a FADISMA para ter acesso a ele. </p>' +
      "</div>",
    showLoaderOnConfirm: true,
    allowOutsideClick: () => !Swal.isLoading(),
  }).then((res) => {
    //usuario visualizou o texto
  });
}

export function handleAceiteMKT() {
  Swal.fire({
    showConfirmButton: true,
    type: "info",
    confirmButtonText: "Dispensar",
    closeOnConfirm: false,
    html:
      '<div class="confirma-assinatura">' +
      '<h2 class="text-2xl font-semibold"><FaCheck class="mr-1" />Autorização para envio de marketing</h2>' +
      '<p class="text-sm text-justify my-4">Ao selecionar a caixa abaixo você dá consentimento para o tratamento de seus dados pessoais informados nesta plataforma para fins de comunicações institucionais e comercias (“e-mail marketing”) referentes a cursos e eventos ofertados por parte da FADISMA e parceiros da área de educação. O tratamento dos dados pessoais será realizado nos termos da Lei n. 13.709/18 (LGPD), respeitando-se as suas garantias e direitos previstos nesta lei. Para mais informações sobre o tratamento de dados pessoais realizado pela FADISMA, exercício de direitos e/ou outras demandas envolvendo seus dados pessoais você poderá acessar nossa Política de Privacidade, enviar um e-mail para o Encarregado (DPO) no endereço: dpo@fadisma.com.br, ou consultar a FADISMA para ter acesso a ele.</p>' +
      "</div>",
    showLoaderOnConfirm: true,
    allowOutsideClick: () => !Swal.isLoading(),
  }).then((res) => {
    //usuario visualizou o texto
  });
}

export async function handleAceiteInscricao(idps, idareainteresse) {
  let termoAceite = "";

  const resTermo = await getTermoAceite(idps, idareainteresse);

  if (resTermo.data.data) {
    termoAceite = resTermo.data.data[0].termoaceite;
  }

  const resModal = Swal.fire({
    showConfirmButton: true,
    showCancelButton: true,
    type: "info",
    confirmButtonText: "Eu aceito | Prosseguir",
    cancelButtonText: "Não aceito",
    closeOnConfirm: false,
    closeOnCancel: true,
    confirmButtonColor: "#059669",
    cancelButtonColor: "#DC2626",
    html:
      '<div class="confirma-assinatura">' +
      '<h2 class="text-2xl font-semibold"><FaCheck class="mr-1" />Termo de aceite</h2>' +
      '<div class="inline-flex items-start mb-4">' +
      /*       '<input type="checkbox" class="mt-2 mr-2 aceite-inscricao" name="aceiteinput" />' +
       */ '<span class="mt-1 text-sm leading-6 p-5 bg-gray-100 rounded  ">' +
      termoAceite +
      "</span></div>" +
      "</div>",
    showLoaderOnConfirm: true,
    didRender: () => {
      /*       document
        .querySelector(".swal2-confirm")
        .setAttribute("disabled", "disabled");

      const elAceite = document.querySelector(".aceite-inscricao");

      elAceite.addEventListener("click", (elAceite) => {
        console.log(elAceite);
        const aceiteMarcado = elAceite.checked;

        if (aceiteMarcado) {
          document.querySelector(".swal2-confirm").removeAttribute("disabled");
        }
      }); */
    },
    preConfirm: () => {},
    allowOutsideClick: () => !Swal.isLoading(),
  }).then((res) => {
    if (res.isConfirmed) {
      return true;
    } else {
      return false;
    }
    /* if (res.value && res.value.data.msg) {
      Swal.fire({
        title: res.value.data.msg,
      });
    } else if (res.isConfirmed && res.value && res.value.status == 200) {
      Swal.fire({
        title:
          "Usuário encontrado: <div class='user-found'>" +
          res.value.data.data[0].cpf +
          "</div>",
      });
    } */
  });

  return resModal;
}
