import { FaWhatsapp } from "react-icons/fa";
import Link from "next/link";

export default function CTAWhats() {
  return (
    <>
      <div>
        <h2 className="text-xl font-medium text-primaria mb-2 mt-3 text-center">
          Dúvidas?
        </h2>

        <a
          href="https://wa.me/555596977997?text=Ol%C3%A1,%20tenho%20d%C3%BAvidas%20sobre%20o%20Sistema%20de%20Inscri%C3%A7%C3%B5es,%20poderia%20me%20ajudar?#sistema_inscricoes"
          target="_blank"
          title="Chamar no WhatsApp"
        >
          <button
            type="submit"
            className="flex items-center justify-center focus:outline-none text-green-600 text-xs sm:text-base rounded py-3 w-full border-green-600 shadow rounded-lg cursor-pointer mb-0 text-base hover:bg-green-600 hover:text-white btn-whats"
          >
            <span>
              <FaWhatsapp className="mr-2" />
            </span>
            <span className="text-base">Chamar no WhatsApp</span>
          </button>
        </a>
      </div>
    </>
  );
}
