import LoginForm from "@/components/forms/login-form.js";
import FirstLoginForm from "@/components/login/first-login.js";
import CTAWhats from "@/components/cta/cta-whats.js";

export default function Login() {
  return (
    <div className="lg:min-h-auto flex flex-col items-center justify-center w-full lg:max-w-lg mt-0 lg:mt-6 mb-0 mx-auto">
      <div className="flex flex-col bg-white shadow rounded-lg px-6 md:px-8 lg:px-12 pt-10 pb-12 md:px-8 w-full max-w-xl lg:min-h-auto mx-auto">
        <FirstLoginForm />

        <div className="relative mt-8 mb-4 h-px bg-gray-300">
          <div className="absolute left-0 top-0 flex justify-center w-full -mt-2">
            <span className="bg-white px-4 text-xs text-gray-500"></span>
          </div>
        </div>

        <LoginForm />

        <div className="relative mt-8 mb-4 h-px bg-gray-300">
          <div className="absolute left-0 top-0 flex justify-center w-full -mt-2">
            <span className="bg-white px-4 text-xs text-gray-500"></span>
          </div>
        </div>

        <CTAWhats />
      </div>
    </div>
  );
}
