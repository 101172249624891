import { useState } from "react";

export default function Footer({ isAbsolute }) {
  const anoAtual = new Date().getFullYear();
  const classTemp =
    isAbsolute == true
      ? "app-footer footer-fadisma mt-4 lg:mt-12 footer-absolute"
      : "app-footer footer-fadisma mt-4 lg:mt-12";

  return (
    <>
      <footer className={classTemp}>
        <p>
          © {anoAtual}. <b>FADISMA</b>. Todos os diretos reservados. CNPJ:
          04.849.608/0001-46
        </p>
      </footer>
    </>
  );
}
